<template>
  <div class="grid grid-cols-12 gap-3">
    <CCard class="col-span-12 lg:col-span-7 px-4 py-8">
      <Login v-if="mode === 'login'" @changeAuthMode="changeMode('personalInformation')">
        {{ $t('login') }}
      </Login>
      <PersonalInformation v-else @changeAuthMode="changeMode('login')">
        {{ $t('personalInformation') }}
      </PersonalInformation>
    </CCard>
    <OrderCalculation
      v-if="selectedContainer"
      class="col-span-12 lg:col-span-5"
      @onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  SET_ANONYMOUS_ORDER,
  SET_DELIVERY_DATE,
  SET_NEXT_ROUTE,
  SET_PICKUP_DATE,
  SET_SELECTED_CONTAINER,
  SET_SELECTED_DELIVERY_AREA,
  SET_SELECTED_MERCHANT_WASTE,
} from '@/store/mutationTypes';
import {
  ANONYMOUS_ORDER,
  DELIVERY_DATE,
  MATCHTING_CONTAINERS,
  PICKUP_DATE,
  SELECTED_CONTAINER,
  SELECTED_DELIVERY_AREA,
  SELECTED_MERCHANT_WASTE,
  STOREFRONT_CONTAINER_SELECTION_LOADING,
  THIS_USER,
} from '@/store/gettersTypes';
import { GET_CONTAINER_SELECTION } from '@/store/actionTypes';
import { OrderCalculation } from '../components';
import Login from '../components/Auth/Login.vue';
import PersonalInformation from '../components/Auth/PersonalInformation.vue';

export default {
  components: {
    Login,
    PersonalInformation,
    OrderCalculation,
  },

  data() {
    return {
      mode: 'personalInformation',
    };
  },

  computed: {
    ...mapGetters([
      SELECTED_MERCHANT_WASTE,
      SELECTED_DELIVERY_AREA,
      SELECTED_CONTAINER,
      DELIVERY_DATE,
      PICKUP_DATE,
      MATCHTING_CONTAINERS,
      STOREFRONT_CONTAINER_SELECTION_LOADING,
      THIS_USER,
      ANONYMOUS_ORDER,
    ]),
  },

  async created() {
    if (this.thisUser) {
      this.$router.push({
        path: '/address',
        query: this.$route.query,
      });
    }

    if (
      this.$route.query &&
      this.$route.query.merchantWasteId &&
      parseInt(this.$route.query.merchantWasteId, 10) &&
      this.$route.query.deliveryAreaId &&
      parseInt(this.$route.query.deliveryAreaId, 10) &&
      this.$route.query.productId &&
      parseInt(this.$route.query.productId, 10) &&
      this.$route.query.deliveryDate &&
      this.$DateTime.fromJSDate(new Date(this.$route.query.deliveryDate)).isValid &&
      this.$route.query.pickupDate &&
      this.$DateTime.fromJSDate(new Date(this.$route.query.pickupDate)).isValid
    ) {
      this.setSelectedMerchantWaste(parseInt(this.$route.query.merchantWasteId, 10));
      this.setSelectedDeliveryArea(parseInt(this.$route.query.deliveryAreaId, 10));
      this.setSelectedContainer(parseInt(this.$route.query.productId, 10));
      this.setDeliveryDate(new Date(this.$route.query.deliveryDate));
      this.setPickupDate(new Date(this.$route.query.pickupDate));

      if (!this.matchingContainers.length) {
        await this.getContainerSelection();
      }
    }

    if (this.$route.query.anonymousOrder) {
      this.setAnonymousOrder({
        ...this.anonymousOrder,
        ...JSON.parse(this.$route.query.anonymousOrder),
      });
    }

    if (
      !this.selectedMerchantWaste ||
      !this.selectedDeliveryArea ||
      !this.selectedContainer ||
      !this.deliveryDate ||
      !this.pickupDate
    ) {
      return this.$router.push('/');
    }

    return this.setNextRoute('/address');
  },

  methods: {
    ...mapMutations([
      SET_SELECTED_MERCHANT_WASTE,
      SET_SELECTED_DELIVERY_AREA,
      SET_SELECTED_CONTAINER,
      SET_DELIVERY_DATE,
      SET_PICKUP_DATE,
      SET_NEXT_ROUTE,
      SET_ANONYMOUS_ORDER,
    ]),
    ...mapActions([GET_CONTAINER_SELECTION]),

    onSubmit() {
      this.$router.push({
        path: '/address',
        query: {
          merchantWasteId: this.selectedMerchantWaste.id,
          deliveryAreaId: this.selectedDeliveryArea.id,
          productId: this.selectedContainer.id,
          deliveryDate: this.deliveryDate.toString(),
          pickupDate: this.pickupDate.toString(),
        },
      });
    },

    changeMode(mode) {
      this.mode = mode;
    },
  },
};
</script>
