<template>
  <div class="flex flex-col justify-center">
    <form
      class="w-full max-w-sm mx-auto px-4"
      @submit.prevent="submitPasswordRequest"
    >
      <CTypo el="div" tstyle="title1" class="mb-6 flex items-center">
        {{ $t('resetPassword') }}<hr class="ml-4 flex flex-1">
      </CTypo>
      <CTextField
        v-model="email"
        type="email"
        :label="$t('email')"
        required
      />
      <CButton
        native-type="submit"
        class="w-full mt-3"
        variant="primary"
      >
        {{ $t('resetPassword') }}
      </CButton>
    </form>
    <div class="w-full max-w-sm mx-auto px-4">
      <CDivider class="my-6" />

      <router-link to="/account">
        <CButton class="w-full">{{ $t('goBackToLogin') }}</CButton>
      </router-link>
    </div>
    <CModal v-model="showSuccessModel" custom-footer no-cancel>
      <template v-slot:header>
        {{ $t('resetPassword') }}
      </template>
      <CAlert variant="success">
        {{ $t('successfulPasswordResetRequestMessage') }}
      </CAlert>
      <template v-slot:footer-right>
        <CButton @click="showSuccessModel = false" variant="primary">{{ $t('ok') }}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { REQUEST_PASSWORD_RESET } from '@/store/actionTypes';

export default {
  data: () => ({
    email: '',
    showSuccessModel: false,
  }),

  methods: {
    ...mapActions([REQUEST_PASSWORD_RESET]),

    async submitPasswordRequest() {
      if (this.email) {
        await this.requestPasswordReset(this.email);
        this.showSuccessModel = true;
      }
    },
  },
};
</script>
