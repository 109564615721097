<template>
  <div class="flex flex-col justify-center">
    <form
      class="w-full max-w-sm mx-auto px-4"
      @submit.prevent="submitPasswordReset"
    >
      <CTypo el="div" tstyle="title1" class="mb-6 flex items-center">
        {{ $t('resetPassword') }}<hr class="ml-4 flex flex-1">
      </CTypo>
      <CPasswordInputs ref="passwordInputs" v-model="passwordInputs" />
      <CButton
        native-type="submit"
        class="w-full mt-3"
        variant="primary"
      >
        {{ $t('save') }}
      </CButton>
    </form>
    <div class="w-full max-w-sm mx-auto px-4">
      <CDivider class="my-6" />

      <router-link to="/login">
        <CButton class="w-full">{{ $t('goBackToLogin') }}</CButton>
      </router-link>
    </div>
    <CModal v-model="showErrorModal" custom-footer>
      <template v-slot:header>
        {{ $t('failedPasswordReset.header') }}
      </template>
      <CAlert variant="danger">
        {{ $t('failedPasswordReset.message') }}
      </CAlert>
      <template v-slot:footer-right>
        <router-link to="/request-password-reset">
          <CButton variant="primary">
            {{ $t('resetPasswordAgain') }}
          </CButton>
        </router-link>
      </template>
    </CModal>
    <CModal v-model="showSuccessModel" custom-footer no-cancel>
      <template v-slot:header>
        {{ $t('resetPassword') }}
      </template>
      <CAlert variant="success">
        {{ $t('successfulPasswordResetMessage') }}
      </CAlert>
      <template v-slot:footer-right>
        <router-link to="/account">
          <CButton variant="primary">{{ $t('loginNow') }}</CButton>
        </router-link>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { RESET_PASSWORD } from '@/store/actionTypes';

export default {
  data: () => ({
    passwordInputs: {
      password: '',
      passwordRepeat: '',
    },
    showErrorModal: false,
    showSuccessModel: false,
  }),

  computed: {
    email() {
      return this.$route.params.email;
    },
    signature() {
      return this.$route.query.signature;
    },
  },

  methods: {
    ...mapActions([RESET_PASSWORD]),

    async submitPasswordReset() {
      const valid = this.$refs.passwordInputs.validate();
      if (valid) {
        try {
          await this.resetPassword({
            email: this.email,
            signature: this.signature,
            password: this.passwordInputs.password,
          });
          this.showSuccessModel = true;
        } catch (e) {
          this.showErrorModal = true;
        }
      }
    },
  },
};
</script>
