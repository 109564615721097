<template>
  <div>
    <form
      class="w-full max-w-sm mx-auto px-4"
      @submit.prevent="submit"
    >
      <CTypo el="div" tstyle="title1" class="mb-6 flex items-center">
        <slot /><hr class="ml-4 flex flex-1">
      </CTypo>

      <CTextField
        v-model="data.firstName"
        :label="$t('firstName')"
        autocomplete="given-name"
        v-autofocus
        required
      />

      <CTextField
        v-model="data.lastName"
        :label="$t('lastName')"
        autocomplete="family-name"
        required
      />

      <CTextField
        v-model="data.email"
        :label="$t('email')"
        type="email"
        autocomplete="email"
        required
      />

      <CTextField
        v-model="data.phone"
        :label="$t('phone')"
        type="tel"
        autocomplete="tel"
        required
      />

      <CCheckbox v-model="terms" required>
        <span v-html="$t('acceptTerms', { href: '/p/agb' })" />
      </CCheckbox>
      <CCheckbox v-model="privacy" required>
        <span v-html="$t('acceptPrivacy', { href: '/p/datenschutz' })" />
      </CCheckbox>

      <CButton
        native-type="submit"
        class="w-full mt-3"
        variant="primary"
      >
        {{ $t('continue') }}
      </CButton>

      <CDivider class="my-6" />
      <CButton class="w-full" @click="$emit('changeAuthMode')">{{ $t('login') }}</CButton>
    </form>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { SET_ANONYMOUS_ORDER } from '@/store/mutationTypes';

import {
  SELECTED_MERCHANT_WASTE,
  SELECTED_DELIVERY_AREA,
  SELECTED_CONTAINER,
  DELIVERY_DATE,
  PICKUP_DATE,
  ANONYMOUS_ORDER,
} from '@/store/gettersTypes';

export default {
  data() {
    return {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        termsVersion: '20210524',
      },
      terms: false,
      privacy: false,
    };
  },

  computed: {
    ...mapGetters([
      SELECTED_MERCHANT_WASTE,
      SELECTED_DELIVERY_AREA,
      SELECTED_CONTAINER,
      DELIVERY_DATE,
      PICKUP_DATE,
      ANONYMOUS_ORDER,
    ]),
  },

  watch: {
    data: {
      deep: true,
      handler() {
        this.setAnonymousOrder({
          ...this.anonymousOrder,
          user: this.data,
        });
      },
    },
  },

  created() {
    if (this.anonymousOrder) {
      this.data = this.anonymousOrder.user;
    }
  },

  methods: {
    ...mapMutations([SET_ANONYMOUS_ORDER]),
    submit() {
      this.$router.push({
        path: '/address',
        query: {
          merchantWasteId: this.selectedMerchantWaste.id,
          deliveryAreaId: this.selectedDeliveryArea.id,
          productId: this.selectedContainer.id,
          deliveryDate: this.deliveryDate.toString(),
          pickupDate: this.pickupDate.toString(),
          anonymousOrder: JSON.stringify(this.anonymousOrder),
        },
      });
    },
  },
};
</script>
